import { onAuthorised } from './responses/onAuthorised';
import { onChallenge } from './responses/onChallenge';
import { onIdentify } from './responses/onIdentify';
import { toRedirect } from './responses/toRedirect';
import { fromRedirect } from './responses/fromRedirect';
import { onRefused } from './responses/onRefused';
import { onError } from './responses/onError';

import { RESPONSE_STATUS } from '../constants/paymentMethodsHub';

import { onResponseParent } from './onResponse';

const mainScope = {};

const storeData = {};

jest.mock('./responses/onAuthorised.js', () => ({
  onAuthorised: jest.fn().mockResolvedValue({}),
}));

jest.mock('./responses/onRefused.js', () => ({
  onRefused: jest.fn().mockResolvedValue({}),
}));

jest.mock('./responses/toRedirect.js', () => ({
  toRedirect: jest.fn().mockResolvedValue({}),
}));

jest.mock('./responses/onChallenge.js', () => ({
  onChallenge: jest.fn().mockResolvedValue({}),
}));

jest.mock('./responses/onIdentify.js', () => ({
  onIdentify: jest.fn().mockResolvedValue({}),
}));

jest.mock('./responses/onError.js', () => ({
  onError: jest.fn().mockResolvedValue({}),
}));

jest.mock('./responses/fromRedirect.js', () => ({
  fromRedirect: jest.fn().mockResolvedValue({}),
}));

const { onComposableResponse, onFromRedirect } = onResponseParent(mainScope, storeData);

describe('Given the "onComposableResponse" method', () => {
  beforeEach(() => {
    jest.resetModules();
  });

  afterEach(() => {
    jest.resetAllMocks();
  });

  describe('When is called with an "authorised" response', () => {
    it('Then the "onAuthorised" method is called', () => {
      const response = {
        originalResponse: {
          providerStatus: RESPONSE_STATUS.authorised,
        },
      };

      onComposableResponse(response);

      expect(onAuthorised).toHaveBeenCalled();
    });
  });

  describe('When is called with an "received" response', () => {
    it('Then the "onAuthorised" method is called', () => {
      const response = {
        originalResponse: {
          providerStatus: RESPONSE_STATUS.received,
        },
      };

      onComposableResponse(response);

      expect(onAuthorised).toHaveBeenCalled();
    });
  });

  describe('When is called with a "refused" response', () => {
    it('Then the "onRefused" method is called', () => {
      const response = {
        originalResponse: {
          providerStatus: RESPONSE_STATUS.refused,
        },
      };

      onComposableResponse(response);

      expect(onRefused).toHaveBeenCalled();
    });
  });

  describe('When is called with a "pending" response', () => {
    it('Then the "toRedirect" method is called', () => {
      const response = {
        originalResponse: {
          providerStatus: RESPONSE_STATUS.pending,
        },
      };

      onComposableResponse(response);

      expect(toRedirect).toHaveBeenCalled();
    });
  });

  describe('When is called with a "challenge" response', () => {
    it('Then the "onChallenge" method is called', () => {
      const response = {
        originalResponse: {
          providerStatus: RESPONSE_STATUS.challenge,
        },
      };

      onComposableResponse(response);

      expect(onChallenge).toHaveBeenCalled();
    });
  });

  describe('When is called with a "identify" response', () => {
    it('Then the "onIdentify" method is called', () => {
      const response = {
        originalResponse: {
          providerStatus: RESPONSE_STATUS.identify,
        },
      };

      onComposableResponse(response);

      expect(onIdentify).toHaveBeenCalled();
    });
  });

  describe('When is called with a [ "error", "cancelled", "presentToShopper" ] responses', () => {
    it('Then the "onError" method is called', () => {
      const response = [
        {
          originalResponse: {
            providerStatus: RESPONSE_STATUS.error,
          },
        },
        {
          originalResponse: {
            providerStatus: RESPONSE_STATUS.cancelled,
          },
        },
        {
          originalResponse: {
            providerStatus: RESPONSE_STATUS.presentToShopper,
          },
        },
      ];

      response.forEach(call => {
        onComposableResponse(call);

        expect(onError).toHaveBeenCalled();
      });
    });
  });

  describe('When is called with a "onFromRedirect" response', () => {
    it('Then the "fromRedirect" method is called', () => {
      const utils = '';
      const currentPath = '';

      onFromRedirect(utils, currentPath);

      expect(fromRedirect).toHaveBeenCalled();
    });
  });
});
